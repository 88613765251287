import storage from "./storage";
import {getJsapiSignature} from "../api/auth";

const jsApiList = [
  'scanQRCode',
  'chooseImage',
  'previewImage',
  'uploadImage',
  'downloadImage',
  'getLocalImgData',
  'updateAppMessageShareData',
  'updateTimelineShareData',
  'onMenuShareWeibo',
  'hideMenuItems',
  'getLocation',
  'openLocation',
  'closeWindow',
  'hideAllNonBaseMenuItem'
];

export function getJsApiList(){
  return jsApiList;
}

export function initWxConfig(callback){
  let url = storage.get('url');
  let param = {url:url}
  getJsapiSignature(param).then(result => {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: result.data.appId, // 必填，公众号的唯一标识
      timestamp: result.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
      signature: result.data.signature,// 必填，签名
      jsApiList: getJsApiList() // 必填，需要使用的JS接口列表
    });
  })
}

/**
 * 微信扫码
 * @param callback
 */
export function scanQRCode(callback) {
  wx.scanQRCode({
    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
    scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
    success: function (res) {
      var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
      callback(result);
    }
  });
}

/**
 * 拍照或从相册选择照片
 * @param callback
 */
export function chooseImage(callback) {
  wx.chooseImage({
    count: 1, // 默认9
    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
    sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
    success: function (res) {
      callback(res.localIds[0])// 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
    }
  })
}

/**
 * 拍照
 * @param callback
 */
export function takeImage(callback) {
    wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res) {
            callback(res.localIds[0])// 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
        }
    })
}


/**
 * 预览图片
 * @param current
 * @param urls
 */
export function previewImage(current, urls) {
  wx.previewImage({
    current: current, // 当前显示图片的http链接
    urls: urls // 需要预览的图片http链接列表
  });
}

/**
 * 上传图片
 * @param localId
 * @param callback
 */
export function uploadImage(localId, callback) {
  wx.uploadImage({
    localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
    isShowProgressTips: 1, // 默认为1，显示进度提示
    success: function (res) {
      callback(res.serverId);// 返回图片的服务器端ID
    }
  });
}

/**
 * 下载图片
 * @param serverId
 * @param callback
 */
export function downloadImage(serverId, callback) {
  wx.downloadImage({
    serverId: serverId, // 需要下载的图片的服务器端ID，由uploadImage接口获得
    isShowProgressTips: 1, // 默认为1，显示进度提示
    success: function (res) {
      callback(res.localId);// 返回图片下载后的本地ID
    }
  });
}

/**
 * 获取本地图片
 * @param localId
 * @param callback
 */
export function getLocalImgData(localId,callback){
  if(window.__wxjs_is_wkwebview !== true){
    callback(localId);
  }else{
    wx.getLocalImgData({
      localId: localId, // 图片的localID
      success: function (res) {
        callback(res.localData)// localData是图片的base64数据，可以用img标签显示
      }
    });
  }
}

/**
 * 获取地理位置
 * @param callback
 */
export function getLocation(callback){
  // callback(30.614767,114.2629035,0,0)
  // callback(30.61713049104882,114.25470298156142,0,0)
  wx.getLocation({
    type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
    success: function (res) {
      var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
      var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
      var speed = res.speed; // 速度，以米/每秒计
      var accuracy = res.accuracy; // 位置精度
      callback(latitude,longitude,speed,accuracy)
    },
    cancel:function(res){

    }
  });
}

/**
 * 使用内置地图查看位置
 * @param latitude
 * @param longitude
 */
export function openLocation(latitude,longitude){
  wx.openLocation({
    latitude: latitude, // 纬度，浮点数，范围为90 ~ -90
    longitude: longitude, // 经度，浮点数，范围为180 ~ -180。
    name: '大武汉1911', // 位置名
    address: '湖北点赞科技有限公司', // 地址详情说明
    scale: 10, // 地图缩放级别,整形值,范围从1~28。默认为最大
    infoUrl: 'http://www.baidu.com' // 在查看位置界面底部显示的超链接,可点击跳转
  });
}

export function closeWindow(){
  wx.closeWindow();
}

export function hideAllNonBaseMenuItem(){
  wx.hideAllNonBaseMenuItem();
}
