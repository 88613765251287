<template>
  <div>
    <div style="display:block;text-align: center;padding-top:80px;">
      <svg v-if="loginResult==1" t="1637302019549" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2468" width="60" height="64"><path d="M512 981.333333C252.8 981.333333 42.666667 771.2 42.666667 512S252.8 42.666667 512 42.666667s469.333333 210.133333 469.333333 469.333333-210.133333 469.333333-469.333333 469.333333z m-50.432-326.101333L310.613333 504.32a32 32 0 0 0-45.226666 45.226667l174.72 174.762666a32.341333 32.341333 0 0 0 0.341333 0.341334l0.256 0.213333a32 32 0 0 0 50.048-6.144l337.450667-379.605333a32 32 0 1 0-47.872-42.496l-318.762667 358.613333z" fill="#58BE6B" p-id="2469"></path></svg>
      <svg v-if="loginResult==2" t="1632895694343" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6928" width="60" height="80"><path d="M549.044706 512l166.189176-166.249412a26.383059 26.383059 0 0 0 0-36.98447 26.383059 26.383059 0 0 0-37.044706 0L512 475.015529l-166.249412-166.249411a26.383059 26.383059 0 0 0-36.98447 0 26.383059 26.383059 0 0 0 0 37.044706L475.015529 512l-166.249411 166.249412a26.383059 26.383059 0 0 0 0 36.98447 26.383059 26.383059 0 0 0 37.044706 0L512 548.984471l166.249412 166.249411a26.383059 26.383059 0 0 0 36.98447 0 26.383059 26.383059 0 0 0 0-37.044706L548.984471 512zM512 1024a512 512 0 1 1 0-1024 512 512 0 0 1 0 1024z" fill="#E84335" p-id="6929"></path></svg>
      <div class="msg">{{msg}}</div>
    </div>
    <div class="close-btn" @click="close" v-if="loginResult>0">我知道了</div>
  </div>

</template>
<script>
import {login} from "../api/login"
import {closeWindow,hideAllNonBaseMenuItem} from "../utils/wx_util"
export default {
  name: "Login",
  data() {
    return {
      state:null,
      loginResult:0,
      msg:''
    }
  },
  mounted() {
    document.title = "";
    this.query = this.$route.query
    this.login();
  },
  created() {
  },
  methods: {
    close(){
      closeWindow();
    },
    async login(){
      let state = this.query.state;
      if(state instanceof Array){
        state = state[0];
      }
      let arr = state.split(".");
      if(arr.length != 2){
        this.loginResult = 2;
        this.msg = "参数错误";
        return;
      }
      const result = await login({ticket:arr[0],userId:arr[1]});
      if(result.code == 20000){
        this.msg = '已成功登录';
        this.loginResult = 1;
      }else{
        if(result.message != undefined && result.message != null && result.message != ''){
          this.msg = result.message;
        }else{
          this.msg = '登录失败';
        }
        this.loginResult = 2;
      }
      wx.ready(function(){
        hideAllNonBaseMenuItem()
      });
    }
  }
}
</script>
<style>
.msg{
  font-size: 18px;
  color: #000000;
  margin-top:20px;
}
.close-btn{
  font-size: 14px;
  color:#4E9862;
  font-weight:bold;
  width:150px;
  height:35px;
  background-color: #F2F2F2;
  line-height:35px;
  border-radius:5px;
  bottom: 120px;
  position: fixed;
  margin: auto;
  left:0;
  right:0;
}
</style>
