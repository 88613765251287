const url = {
    auth: {
        get_auth_info: '/wx/open/getAuthUrl',
        get_auth_token: '/wx/open/getAuthToken',
        get_jsapi_signature: '/wx/open/getJsapiSignature'
    },
    poster:{
        getPosterInfo: '/wx/poster/info'
    },
    login:{
        scanLogin:'wx/open/scanLogin'
    }
}

export default url;
