import axios from '@/utils/axios'
import api from '../api'

export async function getAuthUrl(params) {
  const result = await axios.post(api.auth.get_auth_info, params)
  return result.data
}

export async function getAuthToken(params) {
  const result = await axios.post(api.auth.get_auth_token, params)
  return result.data
}

export async function getJsapiSignature(params) {
  const result = await axios.post(api.auth.get_jsapi_signature, params)
  return result.data
}
