import Vue from 'vue'
import App from './App.vue'
import router from './router'
import storage from './utils/storage'
import util from './utils/util'
import {initWxConfig} from './utils/wx_util'
import {getAuthToken, getAuthUrl} from './api/auth'

import { Button } from 'vant';

Vue.config.productionTip = false
Vue.prototype.storage = storage;
Vue.prototype.util = util;

Vue.use(Button);

let url;
router.beforeEach(async (to, from, next) => {
  const token = storage.get("token");
  if (util.isEmpty(token)) {
    const code = util.getQuery("code");
    if (util.isEmpty(code)) {
      let param = {url: window.location.href}
      const getAuthUrlResult = await getAuthUrl(param);
      if(getAuthUrlResult.code == 20000){
        location.href = getAuthUrlResult.data;
      }
    } else {
      let param = {code: code};
      const getAuthTokenResult = await getAuthToken(param);
      if (getAuthTokenResult.code == 20000) {
        if (!util.isEmpty(getAuthTokenResult.data) && !util.isEmpty(getAuthTokenResult.data.authorization)) {
          storage.set('token', getAuthTokenResult.data.authorization, 120);
          let url = util.delUrlParam('code')
          self.location = url;
        } else {
          alert('微信授权登录失败')
        }
      } else {
        if (getAuthTokenResult.msg == '40163') {
          location.href = window.location.href.split('?')[0];
        } else {
          alert(getAuthTokenResult.msg)
        }
      }
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  if (window.__wxjs_is_wkwebview !== true) {
    url = window.location.href.split('#')[0];
    storage.set('url', url);
  }else{
    url = document.URL;
    storage.set('url', url);
  }
  initWxConfig();
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
