<template>
    <div class="bg">
        <img :src="poster" @click="preview" v-show="poster!=''">
    </div>
</template>

<script>
import {getPosterInfo} from "../api/poster"
import util from "../utils/util"
import {previewImage} from "../utils/wx_util"
export default {
    name: "poster",
    data() {
        return {
            poster:""
        }
    },
    mounted() {
        document.title = "推广海报";
    },
    created() {
        this.getPosterInfo();
    },
    methods: {
        getPosterInfo:async function (){
            const result = await getPosterInfo(new Object());
            console.log(result.data);
            if(result.code == 20000){
                this.poster = result.data;
            }
        },
        preview:function(){
            if(util.isEmpty(this.poster)){
                return;
            }
            previewImage(this.poster,[this.poster])
        }
    }
}
</script>

<style scoped>
.bg{
    background-image: url("../assets/images/back.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height:100%;
    width:100%;
}
img{
    width:85%;
    background-color: white;
    padding:10px;
    margin-top: 40px;
}
</style>
