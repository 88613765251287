import storage from './storage'
import util from './util'
import axios from 'axios'

// const baseURL = 'http://192.168.2.243:8093';
// const baseURL = 'http://tuchuan-sms.vaiwan.com';
const baseURL = process.env.VUE_APP_BASE_API;
console.log(baseURL)

//全局请求的配置
function setHeaders(type) {
  const header = {}
  if(type != undefined && type != null && type == 'json'){
    header['Content-Type'] = 'application/json;charset=utf-8'
  }else{
    header['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
  }
  header['Accept'] = 'application/json'
  const Authorization = storage.get('token');
  if (!util.isEmpty(Authorization)) {
    header['RdAuthorization'] = Authorization
  }
  return header
}

const myAxios = {
    post: async function (url, param) {
        let headers = setHeaders();
        let result = await axios.post(url, param, {
            baseURL: baseURL,
            timeout: 1000 * 15,
            withCredentials: false,
            headers: headers,
            transformRequest: [function (data) {
                let ret = ''
                for (let it in data) {
                    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
                }
                return ret
            }]
        });
        if(!result || result.data.code == 40015 || result.data.code == 401){
            storage.remove('token');
            location.reload();
        }
        return result;
    },
    postJson:async function(url,param,ignoreToken) {
        let headers = setHeaders('json');
        if(ignoreToken != undefined && ignoreToken == true){
            delete headers.RdAuthorization
        }
        let result = await axios.post(url, param, {
            baseURL: baseURL,
            timeout: 1000 * 15,
            withCredentials: false,
            headers: headers,
        });
        if(!result || result.data.code == 40015 || result.data.code == 401){
            storage.remove('token');
            location.reload();
        }
        return result;
    }
}
export default myAxios;
