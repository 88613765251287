const index = {}

String.prototype.replaceAll  = function(s1,s2){
  return this.replace(new RegExp(s1,"gm"),s2);
}

index.isEmpty = function (obj) {
  if (obj == undefined || obj == null || (typeof(obj) == 'string' && (obj == '' || obj == 'undefined' || obj == 'null'))) {
    return true;
  } else {
    return false;
  }
}

index.getQuery = function(paramName) {
    var paramValue = "", isFound = !1;
    if (window.location.search.indexOf("?") == 0 && window.location.search.indexOf("=") > 1) {
        var arrSource = unescape(window.location.search).substring(1, window.location.search.length).split("&"), i = 0;
        while (i < arrSource.length && !isFound) arrSource[i].indexOf("=") > 0 && arrSource[i].split("=")[0].toLowerCase() == paramName.toLowerCase() && (paramValue = arrSource[i].split("=")[1], isFound = !0), i++
    }
    return paramValue == "" && (paramValue = null), paramValue
}

index.delUrlParam = function (ref) {
  var url = window.location.href;
  // 如果不包括此参数
  if (url.indexOf(ref) == -1) {
    return url;
  }
  var arr_url = url.split('?');
  var base = arr_url[0];
  var arr_param = arr_url[1].split('&');
  var index = -1;
  for (var i = 0; i < arr_param.length; i++) {
    var paired = arr_param[i].split('=');
    if (paired[0] == ref) {
      index = i;
      break;
    }
  }
  if (index == -1) {
    return url;
  } else {
    arr_param.splice(index, 1);
    return base + "?" + arr_param.join('&');
  }
}

index.delEmptyParam = function(){
  var url = window.location.href;
  var arr_url = url.split('?');
  if(arr_url.length > 1){
    var base = arr_url[0];
    var arr_param = arr_url[1].split('&');
    if(arr_param.length > 0 && url.indexOf('state=#/') > -1){
      if(arr_param.length == 1 && arr_param[0] == 'state=#/'){
        self.location = base
      }else{
        if(arr_param[0] == 'state=#/'){
          self.location = url.replaceAll('state=#/&','')
        }else{
          self.location = url.replaceAll('&state=#/','')
        }
      }
    }
  }
}

export default index;
