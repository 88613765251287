import Vue from 'vue'
import VueRouter from 'vue-router'
import Poster from '../views/Poster.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Poster',
    component: Poster
  },
  {
    path:'/login',
    name:'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
